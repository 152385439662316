import moment from "moment/moment";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            selected: [],
            selectedSource: 'selected',
            allItems: [],
            tableOptions: {
                currentPage: 1,
                perPage: 20,
                totalRows: 0,
                grandTotal: {},
                pageOptions: [20, 50, 100, 500],
                sortBy: 'id',
                sortDesc: true,
            },
            fields: [],
            params: {},
            savedFilters: [],
            heavyColumns: [],
            saveFilters: false,
            filtersApplied: false,
            watchTriggered: false,
            useSavedFilters: false,
            filter: {},
            firstLoaded: true,
            tableUniqKey: null,
            idFromUrl: false,
        }
    },
    methods: {
        selectRow(val, item) {
            if (val) {
                this.selected.push(item)
            } else {
                this.selected = this.selected.filter((row) => {
                    return row.id !== item.id;
                });
                this.selectedSource = 'selected'
            }
        },
        selectAll(val) {
            if (val) {
                this.selected = this.allItems.filter(item => {
                    return typeof item.selectable == 'undefined' || item.selectable
                })
            } else {
                this.selected = []
            }
        },
        selectSource(val) {
            this.selectedSource = val;
        },
        refreshTable() {
            this.selected = []
            this.selectedSource = 'selected'
            this.firstLoaded = true
            this.$refs.table.refresh()
        },
        changeBaseFilter(value) {
            if (this.saveFilters) {
                value.filterChanged = true
            }

            this.filter = JSON.parse(JSON.stringify(value))
        },
        getSelectedData(table) {
            let data = {
                type: table,
                filter: this.filter,
                page: this.tableOptions.currentPage,
                per_page: this.tableOptions.perPage,
                sorting_by: this.tableOptions.sortBy,
                sorting_desc: this.tableOptions.sortDesc ? 'true' : 'false',
                additionalOptions: this.additionalOptions,
                ids: this.selected.map(row => row.id),
                source: this.selectedSource,
                selectedSource: this.selectedSource,
                export: {
                    ids: this.selected.map(row => row.id),
                    source: this.selectedSource,
                }
            }

            let baseParams = this.ctxBaseParams()
            Object.keys(baseParams).forEach(key => {
                data[key] = baseParams[key]
            })

            let additionalParams = this.ctxAdditionalParams()
            Object.keys(additionalParams).forEach(key => {
                data.filter[key] = additionalParams[key]
            })

            return data
        },
        ctxBaseParams() {
            return {}
        },
        ctxAdditionalParams() {
            return {}
        },
        commonProviderFetch(ctx, dispatchName, getFunctionName, assignCustomParams = {}, callback = null) {
            if (!this.firstLoaded && !this.watchTriggered && ctx.filter.filterChanged != true && this.saveFilters) {
                return null
            }
            this.watchTriggered = false
            if (this.filtersApplied && ctx.filter.filterChanged != true) {
                this.filtersApplied = false
                return null
            }

            let baseParams = this.ctxBaseParams()
            Object.keys(baseParams).forEach(key => {
                ctx[key] = baseParams[key]
            })

            let additionalParams = this.ctxAdditionalParams()
            Object.keys(additionalParams).forEach(key => {
                ctx.filter[key] = additionalParams[key]
            })

            ctx.filter.tableUniqKey = this.tableUniqKey
            if (this.firstLoaded) {
                ctx.columns_only = true
            }

            if (this.$refs.table.$children[0].selectedFilterId) {
                ctx.filter['_selected_filter_id'] = this.$refs.table.$children[0].selectedFilterId
            }

            return this.$store.dispatch(dispatchName, ctx).then(() => {
                return this.commonAfterTableRequest(getFunctionName, assignCustomParams, callback, ctx, dispatchName)
            }).catch(error => {
                this.$children[0].showRefresh = true
            })
        },
        commonAfterTableRequest(getFunctionName, assignCustomParams = {}, callback = null, ctx = null, dispatchName = null) {
            const tableData = typeof getFunctionName === 'string' ? this[getFunctionName]() : getFunctionName;

            this.tableOptions.totalRows = tableData.count
            this.tableOptions.grandTotal = tableData.totalData
            this.tableOptions.sortBy = tableData.params.sorting_by
            this.tableOptions.sortDesc = tableData.params.sorting_desc === "true" ? true : tableData.params.sorting_desc === "false" ? false : Boolean(tableData.params.sorting_desc);

            this.fields = tableData.columns
            this.allItems = tableData.data
            this.savedFilters = tableData.savedFilters
            this.heavyColumns = tableData.heavy_columns

            if ((tableData.params && (this.saveFilters || this.useSavedFilters) && this.firstLoaded)) {
                if (typeof tableData.params.filter != 'undefined') {
                    this.filtersApplied = true
                    this.filter = Array.isArray(tableData.params.filter) ? {} : tableData.params.filter
                }
                if (typeof tableData.params.per_page != 'undefined') {
                    this.filtersApplied = true
                    this.tableOptions.perPage = tableData.params.per_page
                }
            }

            Object.keys(assignCustomParams).forEach(key => {
                this[key] = tableData[assignCustomParams[key]]
            })

            if (tableData.params && typeof tableData.params.filter != 'undefined' && tableData.params.filter.created_at) {
                const dates = tableData.params.filter.created_at.split(';')
                this.month = moment(dates[0], 'DD.MM.YYYY').format('MM.YYYY')
            }

            if (callback) {
                callback(tableData);
            }

            if (ctx && this.firstLoaded) {
                this.firstLoaded = false
                ctx.columns_only = false
                return this.$store.dispatch(dispatchName, ctx).then(() => {
                    return this.commonAfterTableRequest(getFunctionName, assignCustomParams, callback, ctx, dispatchName)
                })
            }

            this.firstLoaded = false


            return tableData.data
        }
    },
    mounted() {
        if (this.idFromUrl) {
            if (this.$route.query && this.$route.query.id) {
                let idFilterData = []
                this.$route.query.id.split(',').forEach(id => {
                    idFilterData.push({id: id})
                })

                this.$set(this.filter, 'id', idFilterData)
                this.$set(this.filter, 'filterChanged', true)
            }
        }
    }
}